import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { Parallax } from 'react-scroll-parallax';
const napszemuvegek = () => (
  <Layout>
    <SEO title="Kontaktlencsék" description="Egyénre szabott optika Zugló szívében"  />
    <div className="sitewrap xl:max-w-2xl justify-center  mr-auto ml-auto      mt-10 ">
    <div className="  flex    mr-auto ml-auto   mt-20">
    <div className="F12F7B p-4      mb-10 mt-10 ">
    



<div className="left2 mt-20 "><h3 className="maintext2 left2 ">UV védelemmel ellátott, és polarizált napszemüvegeket talál nálunk (HEAD, S.Oliver...).</h3>
<h3 className="maintext2 mt-2 left2">
Dioptriás napszemüveg lencsével egyedi napszemüvegeket tudunk készíteni.</h3>

<h3 className="maintext2 mt-2 left2">
Dioptriás lencsével választható:</h3>

<ul className="maintext2 mt-2 left2 text-left"> 
<li>polarizált</li>
<li>tükrös</li>
<li>fényresötétedő</li>

</ul>






</div></div>

    </div></div>
  </Layout>
)

export default napszemuvegek
